import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import frameOne from "../../images/largeFeatImgBG.png";


const LargeFeaturedImg  = props => (
    <div className="largeFeaturedImg">
        <Container className="alter-width">
            <Row className="holdContent">
              <Col className="colImage">
                <div className="holdFrame">
                    <div className="frameImage" style={{backgroundImage: `url(${frameOne})`}}>
                      <img src={props.imgSrc} alt={props.imgAlt} />
                    </div>
                </div>
              </Col>
            </Row>
        </Container>
    </div>
);

export default LargeFeaturedImg;