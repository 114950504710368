import React from "react";


const MediumFeaturedImg  = props => (
    <div className="mediumFeaturedImg">
        <div className="holdImage">
            <img src={props.imgSrc} alt={props.imgAlt} />
        </div>
    </div>
);

export default MediumFeaturedImg;