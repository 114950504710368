import React from "react";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import InternalPageHeader from "../../components/media/InternalPageHeader";
import ImgTextCard from '../../components/media/ImgTextCard';
import ArticleText from "../../components/text/ArticleText";
import TitleTextArticle from "../../components/text/TitleTextArticle";
import {Container, Row, Col} from "react-bootstrap";
import bgInternalImage from "../../images/Smathers-Grand-Reading-Room-Zoom-Background-2752x1392.jpg";
import booksStockImage from "../../images/booksOnShelf.jpg";
import Footer from "../../components/Footer";

class ACCRPArchivalDocs extends React.Component {
  constructor(props){
    super(props);

    this.state = {
       offset: 0,
       archivalDocs: [],
       perPage: 10,
       currentPage: 0
    };

  this.handlePageClick = this
  .handlePageClick
  .bind(this);
 };

 getArchivalDocs() {
  axios.get(`https://api.alachuacounty.us/truth-rec-backend/api/getResources`)
  .then( response => {
    const data =  response;
    const archives = data.data;
    console.log(archives);
    const sliced = archives.slice(this.state.offset, this.state.offset + this.state.perPage) 
    const archivesData = sliced.map(file => <Col md={4} xl={3}>
       <a className="hoverState" href={file.Link} target="_blank" rel="noreferrer"
            ><ImgTextCard 
              siteImg={booksStockImage}
              siteName={`${file.Title.length > 30 ? file.Title.replace(/^(.{30}[^\s]*).*/, "$1") : file.Title}`}
              siteAddress={`${file.Date}`}
           /> </a></Col>)
        this.setState({
          pageCount: Math.ceil(archives.length / this.state.perPage),
          
          archivesData
      });
  });
};

handlePageClick = (e) => {
  const selectedPage = e.selected;
  const offset = selectedPage * this.state.perPage;

  this.setState({
      currentPage: selectedPage,
      offset: offset
  }, () => {
      this.getArchivalDocs();
  });

};
  
componentDidMount() {
  this.getArchivalDocs();
};

  render() {
    return (
      <main className="archivalDocs">
        <InternalPageHeader
          pageHeading={"ACCRP Resources"}
          pageSubHeading={"Archival Documents"}
          inPageBGImage={bgInternalImage}
        />
        <TitleTextArticle h2TitleText={""} />
        <ArticleText
          articleText={`This section includes media and archival resources associated with the activities of the ACCRP and its eight subcommittees since 2020. We have compiled the weblinks for all our publicly held events, available for viewing on YouTube, as well as any available PDF documents and flyers, some of which include soil collection ceremonies, historical marker dedications and more. Those files have been organized chronologically. `}
        />
        <Container className="addMarginArchivePg">
              <Row>
                {this.state.archivesData}
                <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>
                  
              </Row>
            </Container>
        <Footer />
      </main>
    );
  }
}

export default ACCRPArchivalDocs;
