import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';

import ListGroup from 'react-bootstrap/ListGroup';
const ImgTextCard  = props => (
    <div className="imgTextCard">

    <ListGroup.Item style={{borderRadius:'3%', display:'flex', justifyContent:'center',alignItems:'center', padding:'6%', background:'#FAFAFA', height:'100px',boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.25)'}}><a style={{ padding:'1%', color:'#00283C'}} href={props.link}>{props.siteName} </a> </ListGroup.Item> 
{/* <Table striped bordered hover>
      <thead>
        <tr>
          <th>{props.siteName}</th>
          <th>{props.siteName}</th>
          <th>{props.siteAddress}</th>
          <th>{props.siteName}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{props.siteName}</td>
          <td>{props.siteName}</td>
          <td>{props.siteName}</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>{props.siteName}</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>{props.siteName}</td>
        </tr>
        <tr>
          <td>3</td>
          <td colSpan={2}>Larry the Bird</td>
          <td>@twitter</td>
        </tr>
      </tbody>
    </Table>
 */}

{/*  <ButtonGroup aria-label="Basic example">
      <Button variant="secondary">{props.siteName}</Button>
      <Button variant="secondary">{props.siteAddress}</Button>
   
    </ButtonGroup> */}

     {/*    <Card className="imgTextCardContent site-img-bg"  style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0), rgba(0, 40, 60, 0.5), rgba(0, 40, 60, 0.85)), url(${props.siteImg})`}}>
            <Card.Body className="hold-text">
               <span className="card-body-text">
                  <p className="site-name">{props.siteName}</p>
                  <p className="site-address">{props.siteAddress}</p>
               </span>                           
            </Card.Body>
        </Card> */}
    </div>
);

export default ImgTextCard;