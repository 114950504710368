import React from "react";
import InternalPageHeader from "../../components/media/InternalPageHeader";
import { Container, Row, Col, Carousel, CarouselItem } from "react-bootstrap";
// import ArticleText from '../../components/text/ArticleText';
import bgInternalImage from "../../images/IMG_0498.JPG";
import quiltImages from "../../js/quiltImagesArray";
import Footer from "../../components/Footer";

class MemorialQuilts extends React.Component {
  render() {
    return (
      <main className="memorialQuilts">
        <InternalPageHeader
          pageHeading={"Community Quilts"}
          pageSubHeading={"Memorializing Victims of Racial Injustice"}
          inPageBGImage={bgInternalImage}
        />
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                These quilts were created by community members to honor and memorialize the individuals who lost their lives in racial terror lynchings. Each square represents an individual victim from Alachua County. Each community quilt has a corresponding brochure that is archived under the
                  <a style={{textDecoration:'underline'}}href="/resources" className="hyperlinks">
                    "Resources"
                  </a>{" "}
                  tab and includes more information about the victims in Alachua County
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Carousel data-bs-theme="dark">
            {quiltImages.map((quilt) => (
              <CarouselItem>
                <img
                  src={quilt.img_src}
                  alt={quilt.img_caption}
                  className="quiltImg"
                />
                <Carousel.Caption>
                  <h3>{quilt.img_title}</h3>
                  <p>{quilt.img_caption}</p>
                </Carousel.Caption>
              </CarouselItem>
            ))}
          </Carousel>
        </Container>
        <Footer />
      </main>
    );
  }
}

export default MemorialQuilts;
