import React from "react";
import NavigationAlt from "../NavigationAlt";
import { MDBContainer } from "mdb-react-ui-kit";





const InPageHeaderShapesAltNav  = props => (
    <div>
      <NavigationAlt />
        <header className="inpage-header-shapes-alt-nav component" 
        style={{width: "100%", 
                marginBottom: '4rem',
                backgroundImage: `url(${props.inPageBGImage})`
                }}>
       <MDBContainer fluid className="inpage-header-shapes">
         <div className="hold-inpage-headeralt-text">
            <h1 className="internalPageText heading">{props.pageHeading}</h1>
            <h2 className="internalPageText subheading">{props.pageSubHeading}</h2>
         </div> 
         <div className="optionalImage">
            {props.optionalComponent}
            </div>    
       </MDBContainer> 
        </header>
    </div>
);
   


export default InPageHeaderShapesAltNav;