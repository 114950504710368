const readings = [
    {
        title: "Coalitions of Sites of Conscience",
        path: "https://www.sitesofconscience.org/resources/frontpagedialogues/"
    },
    {
        title: "Contested History: Reading List",
        path: "https://contestedhistories.org/resources/reading-list/"
    },
    {
        title: "Equal Justice Initiative Report",
        path: "https://eji.org/reports/"
    },
    {
        title: "Facing History and Ourselves Know, What to Know, Learn",
        path: "https://www.facinghistory.org/resource-library/head-heart-conscience"
    },
    {
        title: " Historic Preservation and Difficult Heritage [Resources for Preservation]",
        path: "https://savingplaces.org/stories/10-tuesday-10-preservation-books"
    },
    {
        title: "National Museum of African American History & Culture Learning Lab",
        path: "https://learninglab.si.edu/org/nmaahc"
    },
    {
        title: "National Museum of African American History & Culture Reading Resources",
        path: "https://nmaahc.si.edu/learn/educators/resources/reading-resources"
    },
    {
        title: "Places of Pain and Shame: Dealing with Difficult Heritage",
        path: "https://www.routledge.com/Places-of-Pain-and-Shame-Dealing-with-Difficult-Heritage/Logan-Reeves/p/book/9780415454506"
    },
   
    {
        title: "Safe Supportive Learning (US Department of Education): Building Trauma-Sensitive Schoold Handout Packet",
        path: "https://safesupportivelearning.ed.gov/sites/default/files/Building%20TSS%20Handout%20Packet_ALL.pdf"
    },
  
    {
        title: "Teaching Hard History",
        path: "https://www.splcenter.org/20180131/teaching-hard-history"
    },




    {
        title: "What We Save, What We Destroy: A Reading List on Difficult Heritage",
        path: "https://longreads.com/2022/07/26/what-we-save-what-we-destroy-a-reading-list-on-difficult-heritage/"
    },
   
    {
        title: "Working with Grief, Trauma, and Related Challenges",
        path: "https://www.rosslaird.com/articles/mental-health/working-with-grief-trauma-and-related-challenges/"
    },
   
    
    {
        title: "Zinn Education Project",
        path: "https://www.zinnedproject.org"
    },
  
   
     
 
   
];

export default readings;
