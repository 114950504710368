import React from "react";
import InternalPageHeader from "../../components/media/InternalPageHeader";
import ArticleText from "../../components/text/ArticleText";
import ImgTextCardTyler from "../../components/media/ImgTextCardTyler";
import PageTitles from "../../components/text/PageTitles";
import bgInternalImage from "../../images/Smathers-Grand-Reading-Room-Zoom-Background-2752x1392.jpg";
import Footer from "../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Candles from "../../images/candlelight.jpg";
import curricula from "../../js/curriculum";
import readings from "../../js/readings";
import tools from "../../js/tools";


import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import curriculum from "../../js/curriculum";



class TraumaInformedTyler extends React.Component {
  render() {
    return (
      <main className="traumaInformed"  id='tools'>
        <InternalPageHeader
          pageHeading={"Trauma-Informed Resources"}
          pageSubHeading={"Important Readings & Tools"}
          inPageBGImage={bgInternalImage}
        />
    <a style={{position:'fixed', bottom:'2%', right:'1%', color:'white',zIndex:999}} href='#tools'>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#E6C200" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
</svg>

</a>
      
        <ArticleText
          articleText={`When dealing with difficult histories, it is crucial to remember the importance of one’s well-being. Anyone who approaches these histories must prioritize their mental health by taking the necessary precautions to ensure their emotional safety and well-being before engaging the subject. `}
        />
        <ArticleText
          articleText={`Below is a list of potential resources to use in preparation for this website’s content, or for when the material on this website becomes too overwhelming. Please remember throughout the process that it is normal to experience a wide range of emotions (e.g., shame, anger, sadness, etc.) when engaging with this material.`}
        />
        <ArticleText
          articleText={`We understand that everyone has their own preferred way of coping and destressing and, therefore, recognize that this list will not aid everyone the same way. There is no one, or ‘right’ way to heal after a traumatic event. There is also no one right way to take care of yourself. The following resources are meant to provide a starting point for anyone who seeks to know more about approaches to difficult histories of racial injustice and trauma-informed mindfulness exercises. This list is not exhaustive and is not meant to be seen as the only resources available.`}
        />
  
       
      


        <Container className="tools">
          <Row>
         
            <Col sm={12} backgroundColor='purple'>
            <Accordion defaultActiveKey={['0']} alwaysOpen='true'>
            <Accordion.Item eventKey="0">
           <Accordion.Header > <Row ><Col style={{ width:'100%', backgroundColor:'green!important'}} sm={12}><h3>Tools</h3></Col></Row> <hr></hr> </Accordion.Header>
           <Accordion.Body>
            <Row>
            <ArticleText
          articleText={`Below you will find a list of tools to aid in your journey with this difficult piece of U.S. history. From meditative 
              practices to learning resources, these tools represent a wide range of aids in the realm of difficult histories. As mentioned, this
               list is not exhaustive but instead meant to provide examples of helpful strategies. `}
        />
            </Row>
                <Row>
          
           {tools.map((tool, key) => {
              return (
                <Col xs={12} sm={12} md={6} lg={4} xl={4}style={{textAlign:'center', color:'#00283C', padding:'1%'}}>
           <a style={{color:'#00283C'}} key={key}  target="_blank" rel="noreferrer" className="hoverState"  href={tool.path}>
       
          <ListGroup variant='flush'  >
                    <ImgTextCardTyler  link={tool.path} siteName={tool.title} />
                    </ListGroup>
          </a>
      
          </Col>
   
              );
            })} 
          
            </Row>

            <Row>
          
        </Row>
                    </Accordion.Body> 
               </Accordion.Item>
             </Accordion>
             </Col>
          </Row>
        </Container>
      
      




        <Container className="readings">
          <Row>
            <Col sm={12} backgroundColor='purple'>
            <Accordion defaultActiveKey={['0']} alwaysOpen='true'>
            <Accordion.Item eventKey="0">
            <Accordion.Header> <Row ><Col style={{ textDecoration:'underline', width:'100%'}} sm={12}></Col><h3>Readings</h3></Row>  </Accordion.Header>
           <Accordion.Body>
            <Row>
            <ArticleText
          articleText={`Many reading lists exist on the topics of difficult histories, racial trauma, racial terror lynchings, enslavement, etc. Below is a curated selection of some of those readings: `}
        />
            </Row>
                <Row>
          
           {readings.map((reading, key) => {
              return (
                <Col xs={12} sm={12} md={6} lg={4} xl={4} style={{textAlign:'center', color:'#00283C', padding:'1%'}}>
           <a style={{color:'#00283C'}} key={key}  target="_blank" rel="noreferrer" className="hoverState"  href={reading.path}>
       
          <ListGroup variant='flush'  >
                    <ImgTextCardTyler  siteImg={Candles} link={reading.path} siteName={reading.title} />
                    </ListGroup>
          </a>
      
          </Col>
   
              );
            })} 
          
            </Row>
                    </Accordion.Body> 
               </Accordion.Item>
             </Accordion>
             </Col>
          </Row>
        </Container>




   
  


        <Container className="curriculum">
          <Row>
            <Col sm={12} >
            <Accordion defaultActiveKey={['0']} alwaysOpen='true'>
            <Accordion.Item  eventKey="0">
            <Accordion.Header > <Row  ><Col style={{  width:'100%'}} sm={12}><h3>Curriculum</h3> </Col> </Row>   </Accordion.Header>
           
           <Accordion.Body>
            <Row>
            <ArticleText
          articleText={`The Alachua County Community Remembrance Project and its partners have developed curriculum plans with a variety of intentions and applications. There are distinct lesson plans for upper-grade levels, as well as curriculum resources for adults. ACCRP would like to extend a special thank you to the University of Florida’s Social Studies Education Program for the generous use of their curriculum.`}
        />
            </Row>
                <Row>
          
           {curricula.map((curriculum, key) => {
              return (
                <Col xs={12} sm={12} md={6} lg={4} xl={4} style={{textAlign:'center', color:'#00283C', padding:'1%'}}>
           <a style={{color:'#00283C'}} key={key}  target="_blank" rel="noreferrer" className="hoverState"  href={curriculum.path}>
       
          <ListGroup variant='flush'  >
                    <ImgTextCardTyler  link={curriculum.path} siteName={curriculum.title} />
                    </ListGroup>
          </a>
      
          </Col>
   
              );
            })} 
          
            </Row>

            <Row>
            <ArticleText
          articleText={`Please note that these curricula relate to difficult heritage topics, namely racial terror and lynchings. These materials will inevitably evoke a personal response and, therefore, it is important to be aware that our responses to certain materials may prove to be unsettling or challenging to come to terms with. For example, some of the following curricula include images of lynchings as evidence of the crimes committed against Black citizens during this period of racial terror and violence. Please be advised before accessing the following curricula that these images may cause difficult emotions for educators and students alike; they may even trigger previous experiences of trauma. We encourage teachers and educators to reflect upon the power imbalances embedded within these images, how they were taken, and for what purposes. It may not be the best practice to continue to show them if the only goal is to shock. We also encourage students to be allowed to shape this conversation and in decision-making process prior to their usage.`}
        />
            </Row>
            <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                For other historical resources, the Library of Congress offers access to state and local newspapers from the era. Florida’s can be found here:
                  <a
                  style={{textDecoration:'underline'}}
                    className="hyperlinks"
                    href="https://chroniclingamerica.loc.gov/newspapers/florida/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Digitized Florida Newspapers
                  </a>.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
                    </Accordion.Body> 
               </Accordion.Item>
             </Accordion>
             </Col>
          </Row>
        </Container>
      
        
        <Footer />
      </main>
    );
  }
}

export default TraumaInformedTyler;
