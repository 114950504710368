import AlachuaNewnansville from "../images/quilts/Alachua_Newnansville.jpg";
import Gainesville from "../images/quilts/Gville_Quilt.jpg";
import Newberry from "../images/quilts/Newberry_quilt.jpg";
import MonteochaGordon from "../images/quilts/Monteocha_Gordan_LeCrosse.jpg";
import YACQuilters from "../images/quilts/Quilt_Youth_Advisory_council_and_Quilters.jpg";

 const quiltImages = [
    {
        img_title: "Alachua and Newnansville Quilt",
        img_caption: `The purpose of this Quilt Project is
        to honor the memory of victims of
        racial violence that took place in
        the nearby town of Newnansville.`,
        img_src: AlachuaNewnansville
    },
    {
        img_title: "Gainesville Quilt",
        img_caption: `The purpose of this quilt is to honor and
        memorialize the victims of racial terror
        in Gainesville, Alachua County, Florida.`,
        img_src: Gainesville
    },
    {
        img_title: "Newberry Quilt", 
        img_caption: `The purpose of this quilt is to honor
        and memorialize the victims of
        racial terror in Newberry, Alachua
        County, Florida.`,
        img_src: Newberry
    },
    {
        img_title: "Monteocha, Gordon, and LaCrosse Quilt",
        img_caption: `The purpose of this quilt is to honor and
        memorialize the victims of racial terror
        in Monteocha, Gordon, LaCrosse, Alachua County, Florida.`,
        img_src: MonteochaGordon
    },
    {
        img_title: "Youth Advisory Council and Quilters",
        img_caption: `Picture of Youth Advisory Council and Quilters`,
        img_src: YACQuilters
    }
   
];

export default quiltImages;
