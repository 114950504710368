import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 

const ArticleText  = props => (
    <div className="articleText">
      <Container>
          <Row>
              <Col className="hold-article">  
                <p className="articleText">{props.articleText}</p> 
              </Col>
          </Row>
      </Container>
    </div>
);

export default ArticleText;