import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col'; 
import { Button, Col } from "react-bootstrap";


const FeaturedImageFlow  = props => (
    <div className="featuredImageFlow hold"  style={{backgroundImage: `url(${props.bgImage})`}}>
        <Container>
            <Row className="featured imageFlow">
               <div className="bgImages featOne" style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0.25), rgba(0, 40, 60, 0.75)), url(${props.featImage1})`}}>
                 <div className="textBox one">
                    <p className="person_name">{props.personName}</p>
                    <p className="date">{props.date}</p>
                    <p className="town">{props.town}</p>
                 </div>
               </div>
               <div className="bgImages featTwo" style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0.25), rgba(0, 40, 60, 0.75)), url(${props.featImage2})`}}>
                 <div className="textBox two">
                    <p className="person_name">{props.personName2}</p>
                    <p className="date">{props.date2}</p>
                    <p className="town">{props.town2}</p>
                 </div>
               </div>
               <div className="bgImages featThree" style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0.25), rgba(0, 40, 60, 0.75)), url(${props.featImage3})`}}>
                 <div className="textBox three">
                    <p className="person_name">{props.personName3}</p>
                    <p className="date">{props.date3}</p>
                    <p className="town">{props.town3}</p>
                 </div>
               </div>
            </Row>
            <Row className="holdGeneralText">
              <Col>
                <h2 className="title">{props.title}</h2>
                <p className="description">{props.description}</p>
                <Button className="cta-btn" href={props.buttonLink}>{props.buttonText}</Button>
              </Col>
            </Row>
        </Container>
    </div>
);

export default FeaturedImageFlow;