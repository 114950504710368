import React from "react";
import InternalPageHeader from "../../components/media/InternalPageHeader";
import ArticleText from "../../components/text/ArticleText";
import TitleTextArticle from "../../components/text/TitleTextArticle";
import FeaturedArticleAlt from "../../components/media/FeaturedArticleAlt";
import Blockquote from "../../components/text/Blockquote";
import bgInternalImage from "../../images/Liberty-Hill-Schoolhouse-cropped.jpg";
import Footer from "../../components/Footer";
import MarkerUnveiling from "../../images/Freddie-Warmack-Park-Unveiling.jpg";
import SoilCollectionImg from "../../images/alachua_newnansville_soil.jpg";
import { Container, Row } from "react-bootstrap";


class History extends React.Component {
  
  render() {
    return (
      <main className="history">
        <InternalPageHeader
          pageHeading={"Preserving History"}
          pageSubHeading={"Memorial & Living Archive"}
          inPageBGImage={bgInternalImage}
        />
        <TitleTextArticle
          h3TitleText={"The Purpose of Preserving This History"}
        />
        <ArticleText
          articleText={`Following the end of the Civil War in 1865, emancipated Black people established homes, farms, churches, schools, and businesses all over Alachua County. Life was hopeful for these Black pioneer families who acquired property and generated communal wealth. However, ongoing hostility and retaliatory violence was an omnipresent threat.
          In November 1868, for example, the white elite of the county jumped into action to affect the outcome of the first presidential election since the end of the war. As this was the first election where freedmen could vote, the Black vote was particularly important, and groups of white men, some members of the Ku Klux Klan, terrorized Black people around the county with violence to keep them away from the ballot box.`}
        />
      
        <ArticleText
          articleText={`The federal government’s military withdrawal from the South, a few years later, would officially end the Reconstruction Era (1865-1877), along with any protection afforded to the freedmen and women living in the South. To maintain the Antebellum-era racial hierarchy, white people attacked Black people for leaving plantations, asserting their rights, participating in politics, working towards economic independence, or for sheer racial terror that was random and arbitrary. Between 1877 and 1950, lynching was used to enforce racial hierarchy and segregation in the Jim Crow era. Gainesville had twelve known victims during this era.`}
        />
        <ArticleText
          articleText={`The impact of racial terrorism profoundly impacted race relations in the United States in ways that persist today. Racial violence forced the exodus of millions of Black people from the South into urban ghettos of the North and West. It created a legacy of racial inequality that has never been adequately addressed in America and continues to sustain racial injustice and bias.`}
        />
        <ArticleText
          articleText={`Preserving this history honors the lives of Black individuals, families, and communities and the everyday spaces and places that these Americans were able to create and sustain in perpetuity of their humanity, connection to the land, and each other.
          The work of preserving, documenting, and reclaiming this history would not have been possible without the contributions and persistence of Black communities in Alachua County, both then and now. We honor the community elders, leaders, and other keepers of this history, who took great care to pass these stories and cultural memories down to their descendants. Your gifts were an act of love and service to humanity. `}
        />
     
        <Container>
          <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
            <FeaturedArticleAlt
              bgImage={MarkerUnveiling}
              title={"Racial Terror History"}
              buttonLink={"/history/racial-terror"}
              buttonText={"See More"}
            />
            <FeaturedArticleAlt
              bgImage={SoilCollectionImg}
              title={"ACCRP & EJI Connection"}
              buttonLink={"/history/accrp-eji"}
              buttonText={"See More"}
            />
          </Row>
        </Container>
        <Blockquote
          quoteText={`" When you stand together, love one another, study,
            and focus, there's nothing you can't do. "`}
          quoteSource={"Dr. Patricia Hilliard-Nunn"}
        />

        <Footer />
      </main>
    );
  }
}

export default History;
