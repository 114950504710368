import React from "react";
import InternalPageHeader from "../../components/media/InternalPageHeader";
import ArticleText from "../../components/text/ArticleText";
import ImgTextCard from "../../components/media/ImgTextCard";
import PageTitles from "../../components/text/PageTitles";
import bgInternalImage from "../../images/Smathers-Grand-Reading-Room-Zoom-Background-2752x1392.jpg";
import Footer from "../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Candles from "../../images/candlelight.jpg";
import curricula from "../../js/curriculum";
import readings from "../../js/readings";
import tools from "../../js/tools";

class TraumaInformed extends React.Component {
  render() {
    return (
      <main className="traumaInformed">
        <InternalPageHeader
          pageHeading={"Trauma-Informed Resources"}
          pageSubHeading={"Important Readings & Tools"}
          inPageBGImage={bgInternalImage}
        />
        <ArticleText
          articleText={`When dealing with difficult histories, it is crucial to remember the importance of one’s own wellbeing. Anyone who 
              approaches these histories must prioritize their mental health by taking the necessary precautions to ensure their own emotional 
              safety and wellbeing before engaging the subject. `}
        />
        <ArticleText
          articleText={`Below is a list of potential resources to use in preparation for this website’s content, or for when the material on 
              this website becomes too overwhelming. Please remember throughout the process that it is normal to experience a wide range of emotions
               (e.g., shame, anger, sadness, etc.) when engaging with this material.`}
        />
        <ArticleText
          articleText={`We understand that everyone has their own preferred ways of coping, de-stressing, etc. and therefore recognize that this
               list will not aid everyone in the same way. There is no one, or ‘right’ way to heal after a traumatic event. There is also no one 
               right way to take care of yourself. The following resources are meant to provide a starting point for anyone who seeks to know more 
               about approaches to difficult histories of racial injustice and trauma-informed mindfulness exercises. This list is not exhaustive 
               and is not meant to be seen as the only resources available.`}
        />
        <PageTitles pageTitleSecond={"Tools"} />
        <ArticleText
          articleText={`Below you will find a list of tools to aid in your journey with this difficult piece of U.S. history. From meditative 
              practices to learning resources, these tools represent a wide range of aids in the realm of difficult histories. As mentioned, this
               list is not exhaustive but instead meant to provide examples of helpful strategies. `}
        />
        <Container className="tools">
          <Row>
          {tools.map((tool, key) => {
              return (
                <Col md={6} lg={4} xl={3}>
                  <a key={key}  target="_blank" rel="noreferrer" className="hoverState" href={tool.path}>
                    <ImgTextCard siteImg={Candles} siteName={tool.title} />
                  </a>
                </Col>
              );
            })}
          </Row>
        </Container>
        <PageTitles pageTitleSecond={"Readings"} />
        <ArticleText
          articleText={`There are many reading lists that exist on the topics of difficult histories, racial trauma, racial terror lynchings, 
              enslavement, etc. Below is a curated selection of some of those readings: `}
        />
        <Container className="readings">
          <Row>
           {readings.map((reading, key) => {
              return (
                <Col md={6} lg={4} xl={3}>
                  <a key={key}  target="_blank" rel="noreferrer" className="hoverState" href={reading.path}>
                    <ImgTextCard siteImg={Candles} siteName={reading.title} />
                  </a>
                </Col>
              );
            })}  
          </Row>
        </Container>
        <PageTitles pageTitleSecond={"Curriculum"} />
        <ArticleText
          articleText={`The Alachua County Community Remembrance Project and their partners have developed curriculum plans with a variety of 
              intentions and applications. There are distinct lesson plans for upper grade levels, as well as curriculum resources for adults. 
              Special ‘thank you’ to the University of Florida’s Social Studies Education Program for the generous use of their curriculum. `}
        />
        <Container className="curriculum">
          <Row>
          {curricula.map((curriculum, key) => {
              return (
                <Col md={6} lg={4} xl={3}>
                  <a key={key}  target="_blank" rel="noreferrer" className="hoverState" href={curriculum.path}>
                    <ImgTextCard siteImg={Candles} siteName={curriculum.title} />
                  </a>
                </Col>
              );
            })}
          </Row>
        </Container>
        <ArticleText
          articleText={`Please note that these curricula relate to difficult heritage topics; namely racial terror and racial terror lynching. 
              It is inevitable that these materials will evoke a personal response, therefore it is important to be aware that our responses to 
              certain materials may prove to be unsettling, or challenging to come to terms with. For example, some of the following curricula 
              include images of racial terror lynchings as evidence of the crimes committed against Black citizens during this period of racial 
              terror and violence. Please be advised before accessing the following curricula that these images may cause difficult emotions for 
              educators and students alike; they may even trigger previous experiences of trauma. We encourage teachers and educators to reflect 
              upon the power imbalances embedded within these images, how they were taken, and for what purposes. It may not be the best practice,
              therefore, to continue to show them if the only goal is to shock. We also encourage that students are given the opportunity to shape 
              this conversation, and in decision making, prior to their usage.`}
        />
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                  For other historical resources, the Library of Congress offers
                  access to state local newspapers from the era; the state of
                  Florida’s can be found here:{" "}
                  <a
                    className="hyperlinks"
                    href="https://chroniclingamerica.loc.gov/newspapers/florida/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Digitized Florida Newspapers
                  </a>.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </main>
    );
  }
}

export default TraumaInformed;
