import React from "react";



const MediumFeaturedImgAlt  = props => (
    <div className="mediumFeaturedImgAlt">
        <div className="holdImage">
            <img src={props.imgSrc} alt={props.imgAlt} />
        </div>
    </div>
);

export default MediumFeaturedImgAlt;