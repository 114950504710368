import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col'; 
import { Button, Col } from "react-bootstrap";



const FeaturedArticle  = props => (
    <div className="featuredArticle">
        <Container className="alter-width">
            <Row className="holdContent">
              <Col className="colImage">
               <div className="holdImage">
                <img src={props.imgSrc} alt={props.imgAlt} />
               </div>
              </Col>
              <Col className="colText">
                <div className="holdText">
                   <h3 className="title">{props.title}</h3>
                   <h4 className="subtitle">{props.subtitle}</h4>
                   <p className="description">{props.description}</p>
                   <Button className="cta-btn" href={props.buttonLink}>{props.buttonText}</Button>
                </div>
              </Col>
            </Row>
        </Container>
    </div>
);

export default FeaturedArticle;