/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

export const heritageSitesContext = createContext([]);

export default function HeritageSitesContext({ children }) {
  const [heritageSites, setHeritageSites] = useState(null);
  const [loading, setLoading] = useState(true);


  const getHeritageSites = async () => {
    try {
       const result = await axios.get(`https://api.alachuacounty.us/truth-rec-backend/api/getBlackHeritageSites`);
       return result;
     
    } catch (error) {
      console.log({ error });
      setLoading(false);
      return [];
    }
  };

  const initialLoad = async () => {
    const result = await getHeritageSites();

    setHeritageSites(result.data);
    setLoading(true);
    console.log("context call");
    //console.log(result.data);
  };

  useEffect(() => {
    initialLoad();
  }, []);

  return <heritageSitesContext.Provider value={{ heritageSites, loading }}>{children}</heritageSitesContext.Provider >;
}
