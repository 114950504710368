import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Carousel, CarouselItem, Container } from "react-bootstrap";
import NavigationAlt from "../../components/NavigationAlt";
import PageTitles from "../../components/text/PageTitles";
import Footer from "../../components/Footer";
//import sitePlaceholder from "../../images/rosewood.jpg";
import sitePlaceholder from '../../images/placeholderImage.png';
import ArticleText from "../../components/text/ArticleText";
import TitleTextArticle from "../../components/text/TitleTextArticle";

function DBHTDetail() {
  const { siteid } = useParams();
  const [site, setSite] = useState("");

  useEffect(() => {
    // console.log('useEffect');
    window.scrollTo(0,0);
    fetch(
      `https://api.alachuacounty.us/truth-rec-backend/api/getBlackHeritageSite/${siteid}`
    )
      .then((response) => {
        const data = response;
        const heritageSite = data.json();
        // console.log(heritageSite);
        return heritageSite;
      })
      .then((heritageSite) => {
        setSite(heritageSite[0]);
      });
  },[]);

  return (
    <main className="blackHeritageDetail">
      <NavigationAlt />
      <Carousel data-bs-theme="dark">
        {/* {quiltImages.map((quilt) => ( */}
        {site.ImageList ? 


            site.ImageList.map((img)=> (        
            <CarouselItem>
              <img
              src={ process.env.PUBLIC_URL + encodeURI(img.relativePath)}
              alt={`Placeholder for Site`}
              className="siteImg"
              />
            </CarouselItem>))

            : 
                    
            <CarouselItem>
            <img
              src={sitePlaceholder}
              alt={`Placeholder for Site`}
              className="siteImg"
            />
            </CarouselItem>
            }

          {/* <Carousel.Caption>
                  <h3>{site.SiteName}</h3>
                </Carousel.Caption> */}
                  {/* <p>{site.img_caption}</p> */}

        {/* ))} */}
      </Carousel>
          <PageTitles pageTitleFirst={site.SiteName} pageSubText={site.Address} />
        <Container className="holdHistoryText">
            {/* Show history title if ProposedHeritageTrailText exists*/} 
           {/*  {site.ProposedHeritageTrailText ? ( <TitleTextArticle h3TitleText={"History"} /> ) : ( "" )}
            <ArticleText articleText={site.ProposedHeritageTrailText} /> */}

            {site.SourcesRichText ? ( <TitleTextArticle h3TitleText={"History"} /> ) : ( "" )}
            {site.SourcesRichText ? <ArticleText articleText={site.SourcesRichText.replace(/(<([^>]+)>)/gi,"")}/> : ""}
            {/* Dynamic Google Map will go here*/}
        </Container>
        <Container fluid>
            <iframe
              title={site.siteName}
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13872.682477530227!2d-82.37773264999998!3d29.627787399999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1700821096486!5m2!1sen!2sus"
              width="100%"
              height="600"
              style={{border:0}}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          <a
            target="_blank"
            href={`http://maps.google.com?q=${site.Address}`}
            rel="noreferrer"
            className="ng-binding hyperlinks"
          >
            {site.Address}
            </a>

       </Container>
      <Footer />
    </main>
  );
}

export default DBHTDetail;
