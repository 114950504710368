import React from "react";
import Navigation from '../Navigation';
// import { Container } from "react-bootstrap";
import { MDBContainer } from "mdb-react-ui-kit";

// const homeHeaderStyles = {
//    width: '100%',
//    background: `linear-gradient(rgba(0, 40, 60, 0.75), rgba(0, 40, 60, 0.5)), url(${bgImage})`
// }

// const headerTextStyles = {

// }

const HomeHeader  = props => (
   <header className="home-header component" style={{backgroundImage:`linear-gradient(rgba(0, 40, 60, 0.75), rgba(0, 40, 60, 0.5)), url(${props.bgImage})`}}>
      <video title={props.videoTitle} playsinline webkit-playsinline muted autoPlay loop className="home-header-video">
         <source src={props.videoBg} type="video/mp4" />  
      </video>
    <Navigation />
       <MDBContainer fluid className="homeHeader">
        <div className="hold-home-header-text">
          <h1 className="home heading">Alachua County Community Remembrance Project</h1>
          <h2 className="home subheading">Truth & Reconciliation</h2>
        </div>     
       </MDBContainer> 
   </header>
);
   


export default HomeHeader;

