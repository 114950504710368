import React from 'react';
import InPageHeaderShapesAltNav from '../../components/media/InPageHeaderShapesAltNav';
import LargeFeaturedImgAlt from "../../components/media/LargeFeaturedImgAlt";
import HawthorneMuseumImg from "../../images/Hawthorne-Historical-Museum-1.jpg";
import { Container, Row } from 'react-bootstrap';
import Footer from '../../components/Footer';
import bgShapesImage from "../../images/shapes-inpage-header.png";



class Calendar extends React.Component {
    render() {
        return (
          <main className="calendar">
            <InPageHeaderShapesAltNav
             inPageBGImage={bgShapesImage}
             pageHeading={"Calendar of Events"}
             pageSubHeading={"Attend One of Our Upcoming Events"}
             optionalComponent={<LargeFeaturedImgAlt imgSrc={HawthorneMuseumImg} imgAlt={"Image of Hawthorne Museum"} />}
             />
             <Container className='accrp-calendar'>
              <Row>
                <div data-tockify-component="calendar" data-tockify-calendar="actruth"></div>
              </Row>
             </Container>

            <Footer />
          </main>
        );
    };
}

export default Calendar;