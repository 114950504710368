import React from "react";
import Navigation from '../Navigation';
import { MDBContainer } from "mdb-react-ui-kit";



// const inPageHeaderStyles = {
  
// }

const InternalPageHeader  = props => (
   <header className="internal-page-header component" 
   style={{width: "100%", 
           height: "75vh", 
           marginBottom: '4rem',
           background: `linear-gradient(rgba(29, 32, 31, 0.50), rgba(29, 32, 31, 0.50)), url(${props.inPageBGImage})`,
           backgroundRepeat: "no-repeat",
           backgroundSize: "cover",
           backgroundPosition: "top center"
           }}>
    <Navigation />
       <MDBContainer fluid className="internalPageHeader">
        <div className="hold-inpage-header-text">
          <h1 className="internalPage heading">{props.pageHeading}</h1>
          <h2 className="internalPage subheading">{props.pageSubHeading}</h2>
        </div>     
       </MDBContainer> 
   </header>
);
   


export default InternalPageHeader;