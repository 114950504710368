import React from 'react';
import {Routes, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Home from './pages/index';
import About from './pages/about/About';
import AboutSankofa from './pages/about/Sankofa';
import History from './pages/history/History';
import ACCRPandEJI from './pages/history/ACCRPEJI';
import RacialTerror from './pages/history/RacialTerror';
import InMemoriam from './pages/inMemoriam/InMemoriam';
import MemorialQuilts from './pages/inMemoriam/MemorialQuilts';
import ACCRPArchivalDocs from './pages/resources/ACCRPArchivalDocs';
import TraumaInformed from './pages/resources/TraumaInformed';
import BlackHeritage from './pages/blackHeritage/BlackHeritage';
import BlackHeritageDetail from './pages/blackHeritage/DBHTDetail';
import Calendar from './pages/calendar/Calendar';
import GetInvolved from './pages/getInvolved/GetInvolved';
import TraumaInformedTyler from './pages/resources/TraumaInformedTyler';


 

function App() {

     return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/about/sankofa" element={<AboutSankofa/>} />
        <Route path="/history" element={<History />} />
        <Route path="/history/accrp-eji" element={<ACCRPandEJI/>} />
        <Route path="/history/racial-terror" element={<RacialTerror/>} />
        <Route path="/in-memoriam" element={<InMemoriam/>} />
        <Route path="/in-memoriam/community-quilts" element={<MemorialQuilts/>} />
        <Route path="/resources" element={<ACCRPArchivalDocs/>} />
        <Route path="/resources/trauma-informed" element={<TraumaInformedTyler/>} />
        <Route path="/black-heritage" element={<BlackHeritage />} />
        <Route exact path="/black-heritage-trail/:siteid" element={ <BlackHeritageDetail/> } />
        <Route path="/calendar" element={<Calendar/>} />
        <Route path="/get-involved" element={<GetInvolved/>} />
      </Routes>
     
    </div>
  );
}

export default App;
