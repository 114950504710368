import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import InPageHeaderShapesAltNav from "../../components/media/InPageHeaderShapesAltNav";
import ArticleText from "../../components/text/ArticleText";
import TitleTextArticle from "../../components/text/TitleTextArticle";
import LargeFeaturedImgAlt from "../../components/media/LargeFeaturedImgAlt";
import MediumFeaturedImg from "../../components/media/MedFeaturedImg";
import MediumFeaturedImgAlt from "../../components/media/MedFeaturedImgAlt";
import Footer from "../../components/Footer";
import EJILegacyMuseum from "../../images/IMG_3898.jpg";
import bgShapesImage from "../../images/shapes-inpage-header.png";
import SoilCollectionImg from "../../images/alachua_newnansville_soil.jpg";
import ACCRPHistoricalMarker from "../../images/accrp-historical-marker.jpg";

class ACCRPandEJI extends React.Component {
  render() {
    return (
      <main className="accrpEji">
        <InPageHeaderShapesAltNav
          inPageBGImage={bgShapesImage}
          pageHeading={"ACCRP & EJI"}
          pageSubHeading={"A Partnership in Preserving History"}
          optionalComponent={
            <LargeFeaturedImgAlt
              imgSrc={EJILegacyMuseum}
              imgAlt={"Legacy Museum signage. Equal Justice Initiative, Montgomery, Alabama"}
            />
          }
        />
        <TitleTextArticle h3TitleText={"How Is ACCRP Connected To EJI?"} />
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                The Alachua County Community Remembrance Project (ACCRP) works in partnership with the <a className="hyperlinks" href="https://eji.org" target="_blank" rel="noreferrer"> Equal Justice Initiative (EJI)</a> of Montgomery, AL to educate our community about our history of racial violence in the Jim Crow era, and its legacy in the persistent racial disparities we face here in Alachua County.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <ArticleText
          articleText={`Since about 2019, the ACCRP has documented nearly 50 lynchings as part of the Truth and Reconciliation process in our county. Much of this work was initially uncovered through Dr. Patricia Hilliard-Nunn’s research into the horrific Newberry lynchings of 1916 . Other historians who assisted us in uncovering our history include Dr. Paul Ortiz, Dr. Sherry Sherrod DuPree, and Karen Kirkman. `}
        />
        <ArticleText
          articleText={`In partnership with the Equal Justice Initiative, the ACCRP has held soil collection ceremonies and dedicated historical markers throughout the county to commemorate this difficult history. `}
        />

        <MediumFeaturedImg
          imgSrc={SoilCollectionImg}
          imgAlt={"Image of the Soil Collection Ceremony"}
        />
        <TitleTextArticle h3TitleText={"Soil Collection Ceremony"} />
        <ArticleText
          articleText={`As part of the Truth and Reconciliation process, communities host ceremonies where they collect soil in jars provided by the Equal Justice Initiative. Soil, a life-giving resource that all life returns to at its end, exists as a physical and symbolic representation of those who have been killed in acts of racial terror and lynchings. While it does not take the place of the person it represents, its collection serves as a reminder of a community’s promise to preserve and protect the memory of those individuals. A second jar for each individual is filled at the same ceremony and sent to the Legacy Museum in Montgomery, Alabama, where it joins soil collection jars from counties across the United States.`}
        />
        <MediumFeaturedImgAlt imgSrc={ACCRPHistoricalMarker} imgAlt={"Reconstruction-Era Lynchings in Gainesville"} />
        <TitleTextArticle h3TitleText={"Historical Markers"} />
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                Historical markers play an important role in educating the public about their community’s shared history with lynchings. Their presence ensures that the memory of what has happened is not forgotten as neighborhoods and towns change. For a list of Historical markers in Alachua County, Florida, visit &nbsp;
                  <a className="hyperlinks" href=" http://apps.flheritage.com/markers/" target="_blank" rel="noreferrer">
                  http://apps.flheritage.com/markers/
                  </a>.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <TitleTextArticle h3TitleText={"High School Essay Contest"} />
        <ArticleText
          articleText={`As part of Truth and Reconciliation and the historical marker dedication, an essay contest is held for public high school students. Students are challenged to write about a historical event of their choice and to connect it to present-day issues..`}
        />
        <Footer />
      </main>
    );
  }
}

export default ACCRPandEJI;
