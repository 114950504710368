import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import InPageHeaderAltNav from "../../components/media/InPageHeaderAltNav";
import PageTitles from "../../components/text/PageTitles";
import ArticleText from "../../components/text/ArticleText";
import ImgTextCard from "../../components/media/ImgTextCard";
import Footer from "../../components/Footer";
import bgInternalImage from "../../images/accrp-historical-marker.jpg";
import bhtMap from '../../images/bht_map_horizontal_c.png'
import sitePlaceholder from "../../images/ccmcc.jpg";
import { heritageSitesContext } from "../../contexts/HeritageSitesContext";


function BlackHeritage(props) {


     const [offset, setOffset] = useState(0);
     const [sites, setSites] = useState([]);
     const [perPage, setPerPage] = useState(20);
     const [currentPage, setCurrentPage] = useState(0);
     const [pageCount, setPageCount] = useState(0);
     const [sitesData, setSitesData] = useState(null);

     const {heritageSites, loading} = useContext(heritageSitesContext);


    //handlePageClick = this.handlePageClick.bind(this);
  

  const getHeritageSites = () => {
    axios
      .get(
        `https://api.alachuacounty.us/truth-rec-backend/api/getBlackHeritageSites`,
      )
      .then((response) => {
        const data = response;
        const places = data.data;
        // console.log(places[0].ImageList[0].relativePath);
        const sliced = places.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const sitesData = sliced.map((site) => (
          // console.log(site.ImageList ? site.ImageList[0].relativePath : sitePlaceholder),
          // console.log(site),
          <Col lg={3} md={4} sm={6}>
            <Link className="hoverState"
              to={{
                pathname: `/black-heritage-trail/${site.MondayID}`,
                state: places,
              }}
            >
              {/* ${ site.ImageList ? site.ImageList[0].relativePath : sitePlaceholder} */}
              <ImgTextCard
              
                //siteImg={`${sitePlaceholder}`}
                siteImg={`${site.ImageList ? process.env.PUBLIC_URL + encodeURI(site.ImageList[0].relativePath) : sitePlaceholder}`}
                siteName={`${site.SiteName.length > 30 ? site.SiteName.replace(/^(.{30}[^\s]*).*/, "$1") : site.SiteName}`}
                siteAddress={`${site.Address.length > 40 ? site.Address.replace(/^(.{40}[^\s]*).*/, "$1") : site.Address}`}
              />
            </Link>
          </Col>
        ));
        
        this.setState({
          pageCount: Math.ceil(places.length / this.state.perPage),

          sitesData,
        });
      });
  }


  const getHeritageSitesFromContext = () => {

    //const {heritageSites, loading} = this.context;
    const places = heritageSites;
    //console.log("FROM CONTEXT SETUP ::::::::::::::: ")
    //console.log(heritageSites);
    //console.log(loading);
        
    // console.log(places[0].ImageList[0].relativePath);
        const sliced = places.slice(
          offset,
          offset + perPage
        );
        const sitesData = sliced.map((site) => (
          // console.log(site.ImageList ? site.ImageList[0].relativePath : sitePlaceholder),
          // console.log(site),
          <Col lg={3} md={4} sm={6}>
            <Link className="hoverState"
              to={{
                pathname: `/black-heritage-trail/${site.MondayID}`,
                state: places,
              }}
            >
              {/* ${ site.ImageList ? site.ImageList[0].relativePath : sitePlaceholder} */}
              <ImgTextCard
              
                //siteImg={`${sitePlaceholder}`}
                siteImg={`${site.ImageList ? process.env.PUBLIC_URL + encodeURI(site.ImageList[0].relativePath) : sitePlaceholder}`}
                siteName={`${site.SiteName.length > 30 ? site.SiteName.replace(/^(.{30}[^\s]*).*/, "$1") : site.SiteName}`}
                siteAddress={`${site.Address.length > 40 ? site.Address.replace(/^(.{40}[^\s]*).*/, "$1") : site.Address}`}
              />
            </Link>
          </Col>
        ));
        
      
          setPageCount(Math.ceil(places.length / perPage));
          setSitesData(sitesData);

  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;


        setCurrentPage(selectedPage);
        setOffset(offset);

        getHeritageSitesFromContext();

  };

  useEffect(()=>{
    
   // const {heritageSites, loading} = ;
    //const places = heritageSites;
    console.log("Use Effect ")
    console.log(heritageSites);
    //console.log(loading);

    if(heritageSites)
{
    getHeritageSitesFromContext();
}
  },[heritageSites]);


    return (
      <main className="blackHeritage">
        <InPageHeaderAltNav inPageBGImage={bgInternalImage} />
        <PageTitles
          pageTitleFirst={"Digital Black Heritage Trail"}
          // pageTitleSecond={"Interactive Map & Search"}
        />
        <ArticleText
          articleText={`The Alachua County Digital Black Heritage Trail map and website aim to resist the systematic, century-long erasure and forced removal of Black life in Alachua County. Demonstrative of the resilience and resistance of Black place-making and institution-building, this map reflects the intergenerational memories of Black belonging in Alachua County. To quote Walter Hood and Grace Mitchell Tada, “Black Landscapes Matter.”`}
        />
        <ArticleText
          articleText={`As a counter-archive of Black cultural memory, this project contributes to both county and nationwide efforts of truth and reconciliation. Only by telling the truth about our shared past can we collectively begin to heal. This project is both a reparative oﬀering and a call to action to build a more just future.`}
        />
        
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">

                <p className="articleText">
                The creation of the Alachua County Digital Black Heritage Trail would not have been possible without the contributions of the ACCRP, its subcommittees, Temple Shir Shalom, local historians Dr. Sherry Sherrod DuPree, Garlenda Greene Grant, UNESCO-TST, Ms. Lizzie Jenkins, Karen Kirkman, Janis Owens, and the UF Museum Studies Program. We are also grateful for our community partners in the cultural heritage sector who contributed their time and expertise to this project: The A. Quinn Jones Museum & Cultural Center, Cotton Club Museum & Cultural Center, Real Rosewood Foundation, Historic Haile Homestead at Kanapaha Plantation, Matheson History Museum, Cultural Arts Coalition, Pleasant Street Historic Society, Samuel Proctor Oral History Program, Visit Gainesville and Alachua County. Additional acknowledgment is owed to the State Archives of Florida for the use of their archival and library resources available at <a className='hyperlinks' href="https://www.floridamemory.com/">FloridaMemory.com</a>, as well as the historical marker database records from the Florida Division of Historical Resources.
                </p>
                <p className="articleText">
                  To add a place of significance to the Digital Black Heritage
                  Trail, please use the Contact form located on our &nbsp;
                  <a className="hyperlinks" href="/get-involved">
                    "Get Involved"
                  </a>{" "}
                  page to send us an email.{" "}
                </p>
                <p className="articleText">
                For a downloadable PDF version of the Digital Black Heritage Trail Map, please click here:  &nbsp;
                  <a className="hyperlinks" rel="noreferrer" target="_blank" href="/files/bht_map_vertical_C_ada.pdf">
                    "Black Heritage Trail Map"
                  </a>.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="holdBHTMap">
           <img src={bhtMap} alt="Digital Black Heritage Trail Map" className="bht-map-image" />
        </div>
        <div className="siteContainerHolder">
          <Container className="holdSites">
            <Row>
            {!sitesData && (<p style={{color:'#FFFFFF'}} > Loading...</p>)}
              {sitesData}
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </Row>
          </Container>
        </div>
        <Footer />
      </main>
    );
  
}

export default BlackHeritage;
