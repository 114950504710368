import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import ACCRPLogo from '../logos/ACCRP_white - Copy.png';
import ACSeal from '../logos/Seal_of_Alachua_County,_Florida.png';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
      const year = new Date().getFullYear();
        return (
          <MDBFooter className='footer-nav text-center text-lg-start'>
    
          <section className='hold-footer-links'>
            <MDBContainer className='mt-5 '>
              <MDBRow className='mt-3 hold-all-columns'>
                <MDBCol md="5" lg="5" xl="5" className='mx-auto mb-4'>
                  <MDBRow className='hold-site-logo-contact'>
                    <MDBCol md="6" lg="7" xl="7" className='hold-accrp-logo-footer'>
                     <Link to="/"><img className="img-fluid footer-logo accrp" src={ACCRPLogo} alt="ACCRP Logo in the Footer" /></Link>
                    </MDBCol>
                    <MDBCol md="6" lg="5" xl="5" className='hold-eji-logo-footer'>
                     <h5 className='text-uppercase fw-bold mb-4 foot-title'>Get in contact</h5>
                     <div className="hold-text">
                       <p className='contact email'><a href="mailto:lgainey@alachuacounty.us">Send Email</a></p>
                       <p className='contact phone'><a href="tel:352-264-6900">352-264-6900</a></p>
                     </div>
                    </MDBCol> 
                  </MDBRow>
                </MDBCol>
                <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4 quickLinks'>
                  <h5 className='text-uppercase fw-bold mb-4 foot-title'>Quick Links</h5>
                  <p className='flink about'><Link to="/about">About ACCRP</Link></p>
                  <p className='flink contact'><Link to="/get-involved">Contact Us</Link></p>
                  <p className='flink accessibility'><a target="_blank" rel='noreferrer' href="https://alachuacounty.us/Depts/EO/Pages/Website-Accessibility.aspx">Accessibility</a></p>
                  {/* <p className='flink privacy'><Link to="/privacy">Privacy Policy</Link></p> */}
                  {/* <p className='flink site-map'><Link to="/site-map">Site Map</Link></p> */}
                   
                </MDBCol>
    
                <MDBCol md="3" lg="3" xl="3" className='mx-auto mb-4 extraResources'>
                  <h5 className='text-uppercase fw-bold mb-4 foot-title'>Extra Resources</h5>
                  <p className='external-links'><a href="https://eji.org/about" target='_blank' rel='noreferrer'> About Equal Justice Initiative</a></p>
                  <p className='external-links'><a href="https://dos.myflorida.com/historical/" target='_blank' rel='noreferrer'> Florida Division of Historical Resources</a></p>
                  <p className='external-links'><a href="https://arts.ufl.edu/academics/art-and-art-history/programs/museum-studies/" target='_blank' rel='noreferrer'> UF Museum Studies</a></p>
                  <p className='external-links'><a href="https://www.floridamemory.com/" target='_blank' rel='noreferrer'> Florida Memory</a></p>
                  <p className='external-links'><a href="https://oral.history.ufl.edu/" target='_blank' rel='noreferrer'>Samuel Proctor Oral History Program</a></p>
                  <p className='external-links'><a href="https://uflib.ufl.edu/" target='_blank' rel='noreferrer'> UF Libraries</a></p>
                
                </MDBCol>
    
                <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-md-0 mb-4'>
                <a href="https://alachuacounty.us/Pages/AlachuaCounty.aspx" target='_blank' rel='noreferrer'><img className="img-fluid footer-logo acounty" src={ACSeal} alt="Alachua County Logo in the Footer" /></a>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
    
          <div className='text-center p-4'>
            <p class="copy">Alachua County Truth & Reconciliation &copy; {year} </p>
          </div>
        </MDBFooter>
        );
    };
}

export default Footer;