import React from "react";
import Footer from "../components/Footer";
import HomeHeader from "../components/media/HomeHeader";
// import bgImage from "../images/home-header_cropped-comp.jpg";
import ACCRPVideo from "../videos/accrp-homepage-video.mp4";
import PreviewTextCTA from "../components/text/PreviewTextCTA";
import FeaturedImageFlow from "../components/media/FeaturedImageFlow";
import FeaturedSection from "../components/media/FeaturedSection";
import FeaturedArticle from "../components/media/FeaturedArticle";
import UpcomingEvents from "../components/media/UpcomingEvents";
import divImg from "../images/line-div.png";
import logoIconImg from "../logos/Sankofa-bold-400w.png";
import image1 from "../images/candlelight.jpg";
import Hawthorne from "../images/Hawthorne-Historical-menu-cropped.jpg";
import HawthorneTwo from "../images/Hawthorne-Historical-Museum-1.jpg";
import LegacyMuseum from "../images/IMG_3898.jpg";
import ACCRPMarker from "../images/bht_map_horizontal_c.png";
import GoldTriangles from "../images/gold-triangles-bg.png";
import RedBGTriangles from "../images/red-bg-triangles-fg.png";

class Home extends React.Component {
    
  render() {
    
    return (
      <main className="homepage">
        <HomeHeader
          videoBg={ACCRPVideo}
          videoTitle={
            "National Memorial for Peace & Justice. Equal Justice Initiative, Montgomery, Alabama."
          }
        />
        <PreviewTextCTA
          logoIconImg={logoIconImg}
          title={"What is Truth & Reconciliation?"}
          dividerImg={divImg}
          description={`On June 26, 2018 the Alachua County Board of County Commissioners (BoCC) began its discussion
          of a process for Truth and Reconciliation to recall our history of racial injustice in order to repair what
          we can, here, in Alachua County. The process was prompted by research completed by the Alachua County Historical 
          Commission (ACHC) in response to work being conducted by the Equal Justice Initiative (EJI) in calling for all 
          communities across the United States to research the histories of racial terror lynching in their specific county.`}
          buttonText={"Learn More"}
          buttonLink={"/about"}
        />
         
        <FeaturedImageFlow
          bgImage={GoldTriangles}
          featImage1={image1}
          personName={"Harry Simonton"}
          town={"Micanopy"}
          date={"1867"}
          featImage2={image1}
          personName2={"Caesar Sullivan"}
          town2={"Newnansville"}
          date2={"1868"}
          featImage3={image1}
          personName3={"Lee Walker"}
          town3={"High Springs"}
          date3={"1916"}
          title={"In Memoriam"}
          description={`The ACCRP honors the victims of racial terror in Alachua County; those known and those unknown.`}
          buttonText={"View More"}
          buttonLink={"/in-memoriam"}
        />
         
        <FeaturedSection
          bgImage={ACCRPMarker}
          title={"Digital Black Heritage Trail"}
          description={`The Alachua County Digital Black Heritage Trail map and website aims to resist the systematic century-long erasure and forced removal of Black life in Alachua county.`}
          buttonText={"Learn More"}
          buttonLink={"/black-heritage"}
        />
        <FeaturedArticle
          imgSrc={LegacyMuseum}
          imgAlt={"The Legacy Museum sign on a wall in Alabama"}
          title={"ACCRP & EJI's Connection"}
          subtitle={"History"}
          description={`The Alachua County Community Remembrance Project (ACCRP) works in partnership with the Equal Justice Initiative (EJI) of Montgomery, 
          AL to educate our community about our history of racial violence in the Jim Crow era, and its legacy in the persistent racial disparities we face here in Alachua County.`}
          buttonLink={"/history/accrp-eji"}
          buttonText={"View More"}
        />
        <FeaturedArticle
          imgSrc={Hawthorne}
          imgAlt={"Hawthorne Historical Museum in Florida"}
          title={"Trauma-Informed Readings"}
          subtitle={"Resources"}
          description={`When dealing with difficult histories, it is crucial to remember the importance of one’s own wellbeing. Anyone who approaches these 
          histories must prioritize their mental health by taking the necessary precautions to ensure their own emotional safety and wellbeing before engaging the subject.`}
          buttonLink={"/resources/trauma-informed"}
          buttonText={"View More"}
        />
        <UpcomingEvents
          /** left side */
          leftBgImage={HawthorneTwo}
          mainEventLink={"https://tockify.com/actruth/detail/30/1701147600000?startms=1701406800000"}
          day={"28"}
          month={"November"}
          eTitle={"Gravesite Remembrance for two children lynched in Newberry"}
          eLocation={"Virtual"}
          eTime={""}
          /** right side */
          rightBgImage={RedBGTriangles}
          title={"Upcoming Events"}
          dividerImg={divImg}
          buttonLink={"/calendar"}
          buttonText={"View All Events"}
        />
        <Footer />
      </main>
    );
  }
}

export default Home;
