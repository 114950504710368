
const events = [
    {
        day: "07",
        month: "December",
        eventTitle: "First Day of Hanukkah",
        eventLocation: "",
        eventTime: "All Day",
        eventLink: ""
    },
    {
        day: "25",
        month: "December",
        eventTitle: "Christmas Day",
        eventLocation: "",
        eventTime: "All Day",
        eventLink: ""
    },
    {
        day: "26",
        month: "December",
        eventTitle: "First Day of Kwaanza",
        eventLocation: "",
        eventTime: "All Day",
        eventLink: ""

    },
    {
        day: "01",
        month: "January",
        eventTitle: "New Year's Day",
        eventLocation: "",
        eventTime: "All Day",
        eventLink: ""
    }
   
];

export default events;