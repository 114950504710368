import React from 'react';
import InternalPageHeader from '../../components/media/InternalPageHeader';
import ArticleText from '../../components/text/ArticleText';
import TitleTextArticle from '../../components/text/TitleTextArticle';
import TextCard from '../../components/text/TextCard';
import bgInternalImage from "../../images/Freddie-Warmack-Park-Unveiling.jpg";
import { Row, Col, Container } from 'react-bootstrap';
import Footer from '../../components/Footer';

class About extends React.Component {
    render() {
        return (
          <main className="aboutPage">
           <InternalPageHeader 
           pageHeading={"About ACCRP"} 
           pageSubHeading={"Truth & Reconciliation"} 
           inPageBGImage={bgInternalImage} />
           <TitleTextArticle h3TitleText={"What is Truth & Reconciliation?"}/>
           <ArticleText 
              articleText={`On June 26, 2018, the Alachua County Board of County Commissioners (BoCC) began its discussion of a process for Truth and Reconciliation to recall our history of racial injustice to repair what we can, here, in Alachua County.`} />
           <ArticleText 
              articleText={`The process was prompted by research completed by the Alachua County Historical Commission (ACHC) in response to work being conducted by the Equal Justice Initiative (EJI) in calling for all communities across the United States to research the histories of racial terror lynching in their specific County. Local research both confirmed and exceeded the EJI’s initial list of lynching victims here in Alachua County, documenting 46 lynchings that occurred between 1867 and 1926. `}/>
           <ArticleText 
              articleText={`The Alachua County Community Remembrance Project (ACCRP) believes that publicly confronting the truth about our history is the first step toward recovery and reconciliation. According to the late Dr. Patricia Hillard-Nunn, our leading community historian, “There’s been silence, but there hasn’t been healing.”`}/>
            <ArticleText 
              articleText={`The history of racial terror remains an unhealed wound in our national landscape and within our communities. This space is an offering for the community to learn, reflect, and heal. Under the guidance of County Commissioners Robert Hutchinson and Charles Chestnut IV, a website, “truth.alachuacounty.org" was set up in the fall of 2019 to document this work throughout the County.`}

           />
           <TitleTextArticle h3TitleText={"ACCRP History"}/>
           <ArticleText
          articleText={`The Alachua County Community Remembrance Project is a coalition of community groups dedicated to working for Truth and Reconciliation. The group began in January 2020, after County Commissioners and community members visited the Equal Justice Initiative (EJI)’s Legacy Museum and National Memorial to Peace and Justice in Montgomery, Alabama. The group was profoundly affected by EJI’s memorial to the victims of lynching during the Jim Crow era and began to explore the history of racial terror and injustice here in Alachua County. `}
        />
          <div className="articleText">
            <Container>
                <Row>
                    <Col className="hold-article">  
                    <p className="articleText">Following EJI’s model, we hold public events to educate our community about this history and its enduring legacies today. The era of racial terrorism has left a persistent mark on Alachua County. It persists in the reality that the median income for Black residents in Alachua County is half that of White residents. It persists in the reality that almost half of Black children here live in poverty, and over 70% of our third-grade Black children cannot read at grade level, while our White children score above state averages. It persists in the reality that our Black youth are almost 10 times more likely to be in a juvenile detention center than our White youth. It persists in the reality that over 70% of incarcerated people in Alachua County are Black people, even though Black people make up less than 20% of our county population. These findings are available in the <a style={{textDecoration:'underline'}} className="hyperlinks" href="https://www.bebr.ufl.edu/sites/default/files/Research%20Reports/ri1_baseline_report.pdf" target="_blank"  rel="noreferrer"> Understanding Racial Inequity in Alachua County report, published in January 2018. </a></p>           
                    </Col>
                </Row>
            </Container>
          </div>
        <ArticleText
          articleText={`
          We have begun the work of speaking the truth and educating our community. We have much work to continue to achieve reconciliation and equity. We recognize and honor the victims of lynching in our County. We are erecting historic markers to remember this history and sponsoring high school Racial Justice essay contests so that our young people know our history. By addressing this history locally, we hope to move towards truth, reconciliation, and healing, as well as encourage action towards institutional inequality, continued marginalization, and unaddressed histories of intergenerational trauma.
          `}
        />
           <TitleTextArticle h2TitleText={"ACCRP Committees"} /> 
           <TitleTextArticle h3TitleText={"Committee Leaders"} /> 
           <Container className='committee-leaders'>
            <Row className='main-row'>
               <Col>
                  <TextCard
                  name={"Charles S. Chestnut, IV"}
                  position={"Chair"}
                  />
               </Col>
               <Col>
                  <TextCard
                  name={"Carl Smart"}
                  position={"County Staff Liaison"}
                  email={"csmart@alachuacounty.us"}
                  />
               </Col>
               <Col>
                  <TextCard
                  name={"Latoya Gainey"}
                  position={"Alachua County Staff Liaison"}
                  email={"lgainey@alachuacounty.us"}
                  />
               </Col>
            </Row>
           </Container>

           <TitleTextArticle h3TitleText={"Subcommittees Throughout Alachua County"} /> 
           <Container className='alachua-county-committees'>
            <Row className='row-two'>
               <Col>
                  <TextCard
                  region={"Alachua/Newnansville"}
                  name={"Shirley Brown"}
                  position={"Commissioner & Team Leader"}
                  email={"sh_brown@cityofalachua.org"}
                  />
               </Col>
               <Col>
                  <TextCard
                  region={"Archer"}
                  name={"Gerie Crawford"}
                  position={"Team Leader"}
                  email={"gerie59@icloud.com"}
                  />
               </Col>
               <Col>
                  <TextCard
                  region={"Gainesville"}
                  name={"Evelyn Fox"}
                  position={"Team Leader"}
                  email={"foxxe1948@gmail.com"}
                  />
               </Col>
               <Col>
                  <TextCard
                  region={"Hawthorne/Waldo"}
                  name={"Mary Jackson"}
                  position={"Team Leader"}
                  email={"amirjonna@windstream.net"}
                  />
               </Col>
               <Col>
                  <TextCard
                  region={"High Springs"}
                  name={"Veloria Kelly"}
                  position={"Team Leader"}
                  email={"veloriak21@gmail.com"}
                  />
               </Col>
               <Col>
                  <TextCard
                  region={"Micanopy"}
                  name={"Jiana Williams"}
                  position={"Team Leader"}
                  email={"Jianacb@gmail.com"}
                  />
               </Col>
               <Col>
                 <TextCard
                  region={"Monteocha/Gordon/Lacrosse"}
                  name={"Ayoka Sowa La"}
                  position={"Team Leader"}
                  email={"niisowala51@gmail.com"}
                  />
               </Col>
               <Col>
                 <TextCard
                  region={"Newberry"}
                  name={"Jordan Marlowe"}
                  position={"Mayor & Team Leader"}
                  email={"Jordan.Marlowe@ci.newberry.fl.us"}
                  />
               </Col>
               <Col>
                  <TextCard
                  region={"Memorial Quilt"}
                  name={"Dawn Beachy"}
                  position={"Team Leader"}
                  email={"dawnegb@gmail.com"}
                  />
               </Col>
   
               <Col>
               <TextCard
                  region={"School Board Essay Contest Committee"}
                  name={"Don Fitzpatrick"}
                  position={"Team Leader"}
                  email={"fitzpatrickdk@gm.sbac.edu"}
                  />
               </Col>
               <Col>
                 <TextCard
                  region={"Community Education Committee"}
                  name={"Veloria Kelly"}
                  position={"Team Leader"}
                  email={"veloriak21@gmail.com"}
                  />
               </Col>
               <Col>
                 <TextCard
                  region={"Real Rosewood Foundation in Archer"}
                  name={"Lizzie Jenkins"}
                  email={"lizzieprj@aol.com"}
                  />
               </Col>
            </Row>
           </Container>
           
           <Footer/>

          </main>
        );
    };
}

export default About;