import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import frameTwo from "../../images/largeFeatImgBG-ALT.png";


const LargeFeaturedImgAlt  = props => (
    <div className="largeFeaturedImgAlt">
        <Container className="alter-width">
            <Row className="holdContent">
              <Col className="colImage">
                <div className="holdFrame">
                    <div className="frameImage" style={{backgroundImage: `url(${frameTwo})`}}>
                      <img src={props.imgSrc} alt={props.imgAlt} />
                    </div>
                </div>
              </Col>
            </Row>
        </Container>
    </div>
);

export default LargeFeaturedImgAlt;