import React from "react";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';


function ContactForm (props)  {

    const [feedback, setFeedback] = React.useState(false);
    const [feedbackStatus, setFeedbackStatus] = React.useState(null);


    const handleSubmit = event => {
        //const form = event.currentTarget;

        //window.alert("test");
    
        event.preventDefault();
        //event.stopPropagation();
    
        console.log("handle submit");
    
        const formData = new FormData(event.target),
        formDataObj = Object.fromEntries(formData.entries())
        console.log(formDataObj);


        fetch("https://api.alachuacounty.us/truth-rec-backend/api/ProcessForm/", {
            //fetch("http://localhost:3001/api/ProcessForm/", {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formDataObj)
        })
            .then((res) => {

                console.log(res.body);

                setFeedback(true);
                setFeedbackStatus(res.status);
                //return res;
            })
            .then((processed) => {

                console.log("processed");

            });


        
      };

    return(
    <div className="contactForm">
      <Container>
          <Row>
            <div className="hold-contactForm">
                <h3 className="form-title">{props.formTitle}</h3>
                {feedback === false && feedbackStatus === null && (
                <Form method="post" className="formContact" onSubmit={handleSubmit}>
                    <Form.Group className="mb-4" controlId="formFullName">
                        <Row>
                            <Col>
                                <Form.Control type="text" placeholder="First Name" name="firstName" require className="nameInputs" />
                            </Col>
                            <Col>
                               <Form.Control type="text" placeholder="Last Name" name="lastName" className="nameInputs" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formEmail">
                        <Form.Control type="email" placeholder="E-mail Address" name="email" className="emailInput"  />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formMessage">
                        <Form.Control as="textarea" rows={10} placeholder="Type message here..."  name="message" className="textAreaInput" />
                    </Form.Group>
                    <Button className="cta-btn" type="submit">
                        Submit
                    </Button> 
                </Form> )} 

                {feedback === true && feedbackStatus === 200 &&
                        <h4 style={{textAlign:"center"}}>
                            Thank you for your submission
                        </h4>
                    }

                {feedback === true && feedbackStatus !== 200 &&
                        <h4 style={{textAlign:"center"}}>
                            Something went wrong, please try again.
                        </h4>
                    }

            </div>
          </Row>
      </Container>
    </div>
    )
    };

export default ContactForm;