import React from 'react';
import InPageHeaderShapesAltNav from '../../components/media/InPageHeaderShapesAltNav';
import ArticleText from '../../components/text/ArticleText';
import LargeFeaturedImgAlt from '../../components/media/LargeFeaturedImgAlt';
import FeaturedArticleAlt from '../../components/media/FeaturedArticleAlt';
import ContactForm from '../../components/text/ContactForm';
import Footer from '../../components/Footer';
import bgShapesImage from "../../images/shapes-inpage-header.png";
import GroupPhotoEJI from "../../images/FullSizeRender.jpg";
import AQuinnJones from "../../images/aqjlogo-sq2.jpeg";
import CCMCC from "../../images/ccmcc.jpg";
import Rosewood from "../../images/rosewood.jpg";
import HaileHH from "../../images/historichaile.jpg";
import Matheson from "../../images/matheson.jpg";
import CulturalArts from "../../images/2017-04-26-Garden-Grown-550x500.jpg";
import PleasantStreet from "../../images/pshs-homes-2.jpg";
import SPOHP from "../../images/spohp.jpg";
import UNESCO from "../../logos/1101px-UNESCO_logo_English.svg.png";
import FAAHPN from "../../images/faahpn_collage_banner.jpg";

import { Container, Row } from "react-bootstrap";

class GetInvolved extends React.Component {
    render() {
        return (
          <main className="getInvolved">
            <InPageHeaderShapesAltNav
             inPageBGImage={bgShapesImage}
             pageHeading={"Get Involved"}
             pageSubHeading={"Stay Engaged and in Contact with ACCRP"}
             optionalComponent={
             <LargeFeaturedImgAlt  
              imgSrc={GroupPhotoEJI}
              imgAlt={"National Memorial for Peace & Justice. Equal Justice Initiative, Montgomery, Alabama"}
              />}
             />
             <ArticleText 
              articleText={`Alachua County is rich with Black history and culture. `} />
             <ArticleText 
              articleText={`This community has witnessed moments of both joy and sorrow, a past that is still embedded 
              in places of significance here. This project seeks to highlight these places in order to make this heritage 
              visible and accessible to the public. If you are interested in furthering this goal, please contact us using the information provided 
              below, and/or get involved in other local organizations working to preserve Black history in Alachua County.  `} />
            <Container>
              <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                  <FeaturedArticleAlt
                  bgImage={AQuinnJones}
                  title={"The A. Quinn Jones Museum & Cultural Center"}
                  buttonLink={"https://www.aqjmuseum.org/about-a-quinn-jones"}
                  buttonText={"See More"}
                />
                  <FeaturedArticleAlt
                  bgImage={CCMCC}
                  title={"The Cotton Club Museum & Cultural Center"}
                  buttonLink={"https://www.cottonclubmuseum.com"}
                  buttonText={"See More"}
                />
        
              </Row>
              <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                  <FeaturedArticleAlt
                  bgImage={Rosewood}
                  title={"The Real Rosewood Foundation"}
                  buttonLink={"https://www.rosewoodflorida.com"}
                  buttonText={"See More"}
                />
                  <FeaturedArticleAlt
                  bgImage={HaileHH}
                  title={"The Historic Haile Homestead at Kanapaha Plantation"}
                  buttonLink={"https://www.hailehomestead.org"}
                  buttonText={"See More"}
                />
             
              </Row>
              <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                  <FeaturedArticleAlt
                  bgImage={Matheson}
                  title={"The Matheson History Museum"}
                  buttonLink={"https://mathesonmuseum.org"}
                  buttonText={"See More"}
                />
                  <FeaturedArticleAlt
                  bgImage={CulturalArts}
                  title={"The Cultural Arts Coalition"}
                  buttonLink={"http://www.culturalartscoalition.org"}
                  buttonText={"See More"}
                />
             
              </Row>
              <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                  <FeaturedArticleAlt
                  bgImage={PleasantStreet}
                  title={"The Pleasant Street Historic Society"}
                  buttonLink={"https://pleasantstreethistoricsociety.org"}
                  buttonText={"See More"}
                />
                  <FeaturedArticleAlt
                  bgImage={SPOHP}
                  title={"Samuel Proctor Oral History Program"}
                  buttonLink={"https://oral.history.ufl.edu"}
                  buttonText={"See More"}
                />
             
              </Row>
              <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                  <FeaturedArticleAlt
                  bgImage={UNESCO}
                  title={"UNESCO-TST"}
                  buttonLink={"http://www.unescotst.com/aboutus.htm"}
                  buttonText={"See More"}
                />
                  <FeaturedArticleAlt
                  bgImage={FAAHPN}
                  title={"Florida African American Heritage Preservation Network (FAAHPN)"}
                  buttonLink={"http://faahpn.com"}
                  buttonText={"See More"}
                />
             
              </Row>
            </Container>
            <br/>
            <ArticleText 
              articleText={`To add a place of significance to the Digital Black Heritage Trail, please use the “Contact Us” tab below to send us an email.`} />
            <ContactForm formTitle={"Get In Contact With Us"} />
            <Footer />
          </main>
        );
    };
}

export default GetInvolved;