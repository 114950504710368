import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col'; 
import { Button, Col } from "react-bootstrap";



const FeaturedSection  = props => (
    <div className="featuredSection" 
    style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0.75), rgba(0, 40, 60, 0.75)), url(${props.bgImage})`}}>
        <Container className="expand-width">
            <Row className="holdFSText">
              <Col>
                <h2 className="title">{props.title}</h2>
                <p className="description">{props.description}</p>
                <Button className="cta-btn" href={props.buttonLink}>{props.buttonText}</Button>
              </Col>
            </Row>
        </Container>
    </div>
);

export default FeaturedSection;