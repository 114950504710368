import React from "react";
import {Container, Row} from 'react-bootstrap';
import leftQuote from "../../images/floralLeftQuote-2.png";
import rightQuote from "../../images/floralRightQuote-2.png";

const Blockquote  = props => (
    <div className="blockquote">
      <Container>
          <Row>
              <div className="hold-quote-content">  
                <div className="top-border"> <img src={leftQuote} alt={"left blockquote border"} /></div>
                    <div className="quoteInfo">
                        <p className="quote-text">{props.quoteText}</p> 
                        <p className="quote-source">{props.quoteSource}</p> 
                    </div>
                <div className="bottom-border"> <img src={rightQuote} alt={"right blockquote border"} /></div>
              </div>
          </Row>
      </Container>
    </div>
);

export default Blockquote;