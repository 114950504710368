import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom';
import ACCRPLogo from '../logos/ACCRP_rev_bold.png';

class NavigationAlt extends React.Component {
    render() {
        return (
          <Navbar expand="lg" className="navigation-alt" >
          <Container>
            <Navbar.Brand><Link to="/"><img className="img-fluid alt-nav-logo" src={ACCRPLogo} alt="ACCRP Logo" /></Link></Navbar.Brand>
            <Navbar.Toggle  aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="alt-nav-collapse">
              <Nav className="ms-auto alt-navbar-nav">
                  {/**About */}
                <NavDropdown title="ABOUT" className="basic-nav-dropdown">
                    <NavDropdown.Item><NavLink className={"nav-link item"} to="/about">About ACCRP</NavLink></NavDropdown.Item>
                    <NavDropdown.Item><NavLink className={"nav-link item"} to="/about/sankofa">About the Sankofa Bird</NavLink></NavDropdown.Item>
                </NavDropdown>
                {/**History */}
                <NavDropdown title="HISTORY" className="basic-nav-dropdown">
                  <NavDropdown.Item><NavLink className={"nav-link item"} to="/history">Preserving History</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className={"nav-link item"} to="/history/accrp-eji">ACCRP and EJI Connection</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className={"nav-link item"} to="/history/racial-terror">Racial Terror</NavLink></NavDropdown.Item>
                </NavDropdown>
                {/**In Memoriam */}
                <NavDropdown title="IN MEMORIAM" className="basic-nav-dropdown">
                  <NavDropdown.Item><NavLink className={"nav-link item"} to="/in-memoriam">In Memoriam</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className={"nav-link item"} to="/in-memoriam/community-quilts">Community Quilts</NavLink></NavDropdown.Item>
                </NavDropdown>
                {/**Resources */}
                <NavDropdown title="RESOURCES" className="basic-nav-dropdown">
                  <NavDropdown.Item><NavLink className={"nav-link item"} to="/resources">ACCRP Resources & Archival Documents</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className={"nav-link item"} to="/resources/trauma-informed">Trauma-Informed Resources</NavLink></NavDropdown.Item>
                </NavDropdown>
                {/**Digital Black Heritage Trail */}
                <NavLink className={"nav-link item"} to="/black-heritage">DIGITAL BLACK HERITAGE TRAIL</NavLink>
                {/**Calendar */}
                <NavLink className={"nav-link item"} to="/calendar">CALENDAR</NavLink>
                {/**Get Involved */}
                <NavLink className={"nav-link item"} to="/get-involved">GET INVOLVED</NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        );
    };
}

export default NavigationAlt;