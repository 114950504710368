import React from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import InternalPageHeader from '../../components/media/InternalPageHeader';
import ImgTextCardAlt from '../../components/media/ImgTextCardAlt';
import ArticleText from '../../components/text/ArticleText';
import {Container, Row, Col} from "react-bootstrap";
import bgInternalImage from "../../images/MLK-Memorial-Garden-2-cropped.jpg";
import Footer from '../../components/Footer';

class InMemoriam extends React.Component {
  constructor(props){
    super(props);

    this.state = {
       offset: 0,
       memoriam: [],
       perPage: 20,
       currentPage: 0
    };

  this.handlePageClick = this
  .handlePageClick
  .bind(this);

 };
  getMemoriam() {
    axios.get(`https://api.alachuacounty.us/truth-rec-backend/api/getInMemoriam`)
    .then( response => {
      const data =  response;
      const memoriams = data.data;
      console.log(memoriams);
      const sliced = memoriams.slice(this.state.offset, this.state.offset + this.state.perPage) 
      const memoriamsData = sliced.map(memoriam => <Col md={6} lg={4} xl={3}><ImgTextCardAlt 
          personName={`Name: ${memoriam.NameOfPerson}`}
          hometown={`Town: ${memoriam.Town}`}
          dateOfDeath={`Year: ${memoriam.BYDATE}`} 
             /> </Col>)
          this.setState({
            pageCount: Math.ceil(memoriams.length / this.state.perPage),
            
            memoriamsData
        });
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.getMemoriam();
    });

  };
    
  componentDidMount() {
    this.getMemoriam();
  };
  render() {
    return (
      <main className="inMemoriam">
        <InternalPageHeader 
        pageHeading={"In Memoriam"} 
        pageSubHeading={"Honoring Those Who Came Before Us"} 
        inPageBGImage={bgInternalImage} />
        <ArticleText articleText={`The ACCRP honors the victims of racial terror in Alachua County, those known and unknown.`} />
        <ArticleText articleText={`During the Jim Crow Era, lynchings went widely unreported. In some cases, local newspapers didn’t even report the names of the victims they wrote about. Newspapers and court records that do exist, give some insight into the deaths of these individuals but tell nothing about who they were in life.`} />
        <ArticleText articleText={`We may never know about the full lives of each of the victims of racial terror lynching, but we do know that they were fathers, mothers, sisters, brothers, sons, daughters, aunts and uncles, and the relatives of descendants within our community. We honor their lives and their memories through our efforts to tell the truth about this painful history. Although we do not know their life stories, we know that many were targeted for their role as community leaders, organizers, and landowners.`} />
            <Container>
              <Row>
                {!this.state.memoriamsData && (<ArticleText articleText={`Loading...`}/>)}
                {this.state.memoriamsData}
                <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>
                  
              </Row>
            </Container>
        <Footer/>
      </main>
    );
  };
}

export default InMemoriam;