import React from "react";
import NavigationAlt from "../NavigationAlt";



const InPageHeaderAltNav  = props => (
    <div>
      <NavigationAlt />
        <header className="inpage-header-alt-nav component" 
        style={{width: "100%", 
                marginBottom: '4rem',
                background: `url(${props.inPageBGImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center"
                }}>
        </header>
    </div>
);
   


export default InPageHeaderAltNav;