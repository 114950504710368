import React from 'react';
import InternalPageHeader from '../../components/media/InternalPageHeader';
import ArticleText from '../../components/text/ArticleText';
import TitleTextArticle from '../../components/text/TitleTextArticle';
import LargeFeaturedImgAlt from '../../components/media/LargeFeaturedImgAlt';
import PeaceAndJusticeImg from "../../images/PeaceandJustice-Exh.jpg";
import bgInternalImage from "../../images/accrp-historical-marker.jpg";
import Footer from '../../components/Footer';

class RacialTerrorHistory extends React.Component {
    render() {
        return (
          <main className="racialTerror">
            <InternalPageHeader 
            pageHeading={"Racial Terror Lynching"} 
            pageSubHeading={"The History"} 
            inPageBGImage={bgInternalImage} />
            <TitleTextArticle h3TitleText={"History of Racial Terror Lynching"}/>
            <ArticleText 
              articleText={`From Alachua/Newnansville historical marker, written by Dr. Paul Ortiz`} />
           <ArticleText 
              articleText={`Between 1865 and 1950, at least 6500 Black people were victims of lynching and white mob violence in the United States. During the period of Reconstruction that followed the Civil War, many white Southerners in the South tried to uphold an ideology of white supremacy and used fatal violence to reinforce racial subordination and segregation. By the end of Reconstruction in 1877, federal courts and Congress had abandoned enforcing laws to protect Black people. By ceding political control back to Confederate veterans, federal officials allowed white Southerners to violently oppose racial equality through intimidation and terror. Lynching emerged as the most public and notorious form of racial terrorism.`} />
           <ArticleText 
              articleText={`White people seeking to maintain the racial hierarchy that existed during enslavement attacked and lynched Black people for asserting their rights, leaving plantations, participating in politics, working toward economic independence, and violating white social customs, being accused of crimes, or for sheer racial terror. State and federal officials tolerated these lawless killings of Black people by failing to hold white mobs accountable for their crimes. Although many victims of racial terror lynching will never be known, at least 319 lynchings have been documented in Florida, with at least 27 known to have taken place in Alachua County between 1865 and 1950. `} />
           <LargeFeaturedImgAlt
            imgSrc={PeaceAndJusticeImg}
            imgAlt={`"Raise Up", by Hank Willis Thomas at the National Memorial for Peace & Justice, Montgomery, Alabama.`}
           />   
           
           <Footer/>
          </main>
        );
    };
}

export default RacialTerrorHistory;