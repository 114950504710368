import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 

const TitleTextArticle  = props => (
    <div className="titleTextArticle">
      <Container>
          <Row>
              <Col className="col-title">  
                 <div className="hold-titles">
                    <h2 className="h2-title">{props.h2TitleText}</h2>
                    <h3 className="h3-title">{props.h3TitleText}</h3>
                 </div>
              </Col>
          </Row>
      </Container>
    </div>
);

export default TitleTextArticle;