import React from "react";
import {Container, Row} from 'react-bootstrap';
import divImg from "../../images/line-div.png";

const PageTitles  = props => (
    <div className="pageTitles">
      <Container>
          <Row>
            <div className="hold-titles">
                <h2 className="second-title">{props.pageTitleSecond}</h2>
                <h1 className="first-title">{props.pageTitleFirst}</h1>
                <div className="hold-divImg">
                    <img src={divImg} alt={"line divider for page title text"} />
                </div>
                <h6 className="sub-title">{props.pageSubText}</h6>
            </div>
          </Row>
      </Container>
    </div>
);

export default PageTitles;