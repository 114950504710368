

const curriculum = [


    {
        title: "Eulogy for Congressman John Lewis",
        path: "/files/curriculum/Eulogy-for-Congressman-John-Lewis.docx"
    },
    {
        title: "FHAO Teaching Current Events Guide",
        path: "/files/curriculum/FHAO-Teaching-Current-Events-Guide.pdf"
    },
    {
        title: "General Lesson Plan (Grades 5-8)",
        path: "/files/curriculum/Lesson-Plan-(5-8).pdf"
    },
    {
        title: "General Lesson Plan (Grades 10-12)",
        path: "/files/curriculum/Lesson-Plan-(10-12)-2.pdf"
    },

    {
        title: "Jessica Morey Lesson Plan on Truth and Reconciliation",
        path: "/files/curriculum/Jessica-Morey-Lesson-Plan-on-Truth-and-Reconciliation.docx"
    },
    {
        title: "John Oliver Examines Whitewashed US History",
        path: "/files/curriculum/John-Oliver-Examines-Whitewashed-US-History.docx"
    },
    {
        title: "Legacy of John Lewis",
        path: "/files/curriculum/Legacy-of-John-Lewis.docx"
    },
    {
        title: "Letter To Parents about Racial Violence Curriculum",
        path: "/files/curriculum/Letter-to-Parents-Revised-081120.docx"
    },
    {
        title: "Letter To Students about Racial Violence Curriculum",
        path: "/files/curriculum/Letter-to-Students-Revised-081120.docx"
    },
    {
        title: "Newberry Middle School Lesson Plans",
        path: "/files/curriculum/Newberry-Middle-School-Lesson-Plans.docx"
    },
     
    {
        title: "Racial Violence Unit Part 1 Student Handouts",
        path: "/files/curriculum/Racial-Violence-Unit-Part-1-Student-Handouts.docx"
    },
    {
        title: "Racial Violence Unit Part 2 Student Handouts",
        path: "/files/curriculum/Racial-Violence-Unit-Part-2-Student-Handouts.docx"
    },
    {
        title: "Racial Violence Unit Part 3 Student Handouts",
        path: "/files/curriculum/Racial-Violence-Unit-Part-3-Student-Handouts.docx"
    }
    ,
    {
        title: "Racial Violence Unit Part 3 Student Photo Analysis Worksheet",
        path: "/files/curriculum/Racial-Violence-Unit-Part3-Student-Photo-Analysis-Worksheet.pdf"
    }
    ,
    {
        title: "Racial Violence Unit Part 3 Student Written Document Analysis Worksheet",
        path: "/files/curriculum/Racial-Violence-Unit-Part3-Student-Written-Document-Analysis-Worksheet.pdf"
    }
    ,
    {
        title: "Racial Violence Unit Part 4 Student Handouts",
        path: "/files/curriculum/Racial-Violence-Unit-Part4-Student-Handouts.docx"
    }
  
    ,
    {
        title: "Racial Violence Unit Part 5 Student Handouts",
        path: "/files/curriculum/Racial-Violence-Unit-Part5-Student-Handouts.docx"
    }
    ,
    {
        title: "Racial Violence Unit Part 6 Student Handouts",
        path: "/files/curriculum/Racial-Violence-Unit-Part6-Student-Handouts.docx"
    }
    ,
    {
        title: `Racial Violence Unit Plan (Updated November 2022)`,
        path: "/files/curriculum/Racial-Violence-Unit-Plan-updated-nov-2022.docx"
    },
    {
        title: "Safe Supportive Learning (US Department of Education): Building Trauma-Sensitive School Handout Packet",
        path: "https://safesupportivelearning.ed.gov/sites/default/files/Building%20TSS%20Handout%20Packet_ALL.pdf"
    },
    {
        title: "Teaching Tolerance Let's Talk Guide For Teachers",
        path: "/files/curriculum/Teaching-Tolerance-Let_s-Talk-Guide-For-Teachers.pdf"
    },
   
    {
        title: "The Story of Strange Fruit",
        path: "/files/curriculum/The-Story-of-Strange-Fruit.docx"
    },
    {
        title: "Who Was Emmett Till",
        path: "/files/curriculum/Who-Was-Emmett-Till_ copy.docx"
    },
   
    
    
];

export default curriculum;