import React from "react";
// import Col from 'react-bootstrap/Col'; 
import { Button, Col } from "react-bootstrap";



const FeaturedArticleAlt  = props => (
    <div className="featuredArticleAlt"  style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0.75), rgba(0, 40, 60, 0.75)), url(${props.bgImage})`}}>
      <Col className="colText">
        <div className="holdText">
            <h3 className="title">{props.title}</h3>
            <Button className="cta-btn" href={props.buttonLink}>{props.buttonText}</Button>
        </div>
      </Col>
    </div>
);

export default FeaturedArticleAlt;