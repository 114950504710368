import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Button, Col } from "react-bootstrap";
import events from "../../js/events";


const UpcomingEvents  = props => (
 
  
    <div className="upcomingEvents">
        <Container className="expand-width">
            <Row className="holdUpcomingEvents">
              <Col className="eventImgCol">
                <div className="eventImgBG" style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0.75), rgba(0, 40, 60, 0.75)), url(${props.leftBgImage})`}}>
                  <a href={props.mainEventLink} target="_blank" rel="noreferrer" className="mainEventLink">
                    <div className="mainEvent">
                      <span className="day">{props.day}</span>
                      <span className="month">{props.month.slice(0,3)}</span>
                      <div className="eventDetails">
                        <span className="e-title">{props.eTitle}</span>
                        <span className="e-location">{props.eLocation}</span>
                        <span className="e-time">{props.eTime}</span>
                      </div>
                    </div>
                  </a>
                </div>
              </Col>
              <Col>
                <div className="redImgBG"  style={{backgroundImage: `url(${props.rightBgImage})`}}>
                  <div className="holdTitle">
                    <h4 className="title">{props.title}</h4>
                    <img className="line-div" src={props.dividerImg} alt="text divider" />
                  </div>
                  <div className="events">
                    {events.map((data) => ( 
                      // <a href={data.eventLink} target="_blank" rel="noreferrer" className="eachEventLink">
                        <div className="eachEvent">
                          <span className="eventDay">{data.day}</span>
                          <span className="eventMonth">{data.month.slice(0, 3)}</span>
                          <div className="eventInfoDetails">
                            <span className="eventTitle">{data.eventTitle}</span>
                            <span className="eventLocation">{data.eventLocation}</span>
                            <span className="eventTime">{data.eventTime}</span>
                          </div>
                        </div>                     
                      // </a>
                      ))}
                  </div>
                  <Button className="cta-btn-blue" href={props.buttonLink}>{props.buttonText}</Button>
                </div>
              </Col>
            </Row>
        </Container>
    </div>
);

export default UpcomingEvents;