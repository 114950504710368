import React from "react";
import Container from 'react-bootstrap/Container';
import { Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 

const PreviewTextCTA  = props => (
    <div className="previewTextCTA hold">
        <Container>
            <Row>
                <Col className="preview-text">
                <img className="icon-logo" src={props.logoIconImg} alt="logo, icon-sized" />   
                 <h2 className="title">{props.title}</h2>
                 <div className="holdDivider">
                    <img className="line-div" src={props.dividerImg} alt="text divider" />
                 </div>
                 <p className="description">{props.description}</p>
                 <Button className="cta-btn" href={props.buttonLink}>{props.buttonText}</Button>
                </Col>
            </Row>
        </Container>
    </div>
);

export default PreviewTextCTA;