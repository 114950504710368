import React from "react";
import Card from 'react-bootstrap/Card';

const ImgTextCard  = props => (
    <div className="imgTextCard">
        <Card className="imgTextCardContent site-img-bg"  style={{backgroundImage: `linear-gradient(rgba(0, 40, 60, 0), rgba(0, 40, 60, 0.5), rgba(0, 40, 60, 0.85)), url(${props.siteImg})`}}>
            <Card.Body className="hold-text">
               <span className="card-body-text">
                  <p className="site-name">{props.siteName}</p>
                  <p className="site-address">{props.siteAddress}</p>
               </span>                           
            </Card.Body>
        </Card>
    </div>
);

export default ImgTextCard;