import React from "react";
import Card from 'react-bootstrap/Card';
// import SankofaBird from "../../logos/Sankofa-bold-400w.png";
import CandleLight from "../../images/candlelight.jpg";

const ImgTextCardAlt  = props => (
    <div className="imgTextCardAlt">
        <Card className="imgTextCardAltContent" id="img-card-content">
            <div className="img-card-fix" style={{backgroundImage: `url(${CandleLight})`}}> </div>
            <Card.Body className="hold-card-text">
                <span className="card-body-text">
                  <p className="name">{props.personName}</p>
                  <p className="town">{props.hometown}</p>
                  <p className="death-date">{props.dateOfDeath}</p>
                  <p className="death-method">{props.deathMethod}</p>
                  <p className="site-address">{props.allegedCrime}</p>
                  <p className="specific-location">{props.specificLocation}</p>
                </span>                       
            </Card.Body>
        </Card>
    </div>
);

export default ImgTextCardAlt;