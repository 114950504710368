import React from "react";
import Card from 'react-bootstrap/Card';

const TextCard  = props => (
    <div className="textCard">
        <Card className="textCardContent">
            <Card.Body>
                <h6 className="alachua-region">{props.region}</h6>
                <span className="card-body-text">
                    <h4>{props.name}</h4> 
                    <p className="position">{props.position}</p>
                    <p className="email">{props.email}</p>
                </span>                       
            </Card.Body>
        </Card>
    </div>
);

export default TextCard;