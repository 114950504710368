const tools = [

    {
        title: "Community Resilience Tool-Kit",
        path: "/files/tools/Remembrance-Project-TIC-Toolkit.docx"
    },
    {
        title: "FHAO Fostering Civil Discourse",
        path: "/files/tools/FHAO-Fostering-Civil-Discourse.pdf"
    },
    {
        title: "FHAO Head Heart Conscience",
        path: "/files/tools/FHAO-Head-Heart-Conscience.docx"
    },
    {
        title: "Museum Guide",
        path: "/files/tools/Museum-Guide.pdf"
    },
   
    {
        title: "Trauma Informed Care",
        path: "https://gucchd.georgetown.edu/TraumaInformedCare/"
    },
    
   

  
   
];


export default tools;