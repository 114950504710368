import React from "react";
import InternalPageHeader from "../../components/media/InternalPageHeader";
import ArticleText from "../../components/text/ArticleText";
import TitleTextArticle from "../../components/text/TitleTextArticle";
import bgInternalImage from "../../images/dr-nunn-sankofa-cropped.jpg";
import LargeFeaturedImg from "../../components/media/LargeFeaturedImg";
import Blockquote from "../../components/text/Blockquote";
import SankofaStatueImg from "../../images/sankofa-bird-statue-gville.jpg";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";

class AboutSankofa extends React.Component {
  render() {
    return (
      <main className="aboutSankofa">
        <InternalPageHeader
          pageHeading={"About the Sankofa Bird"}
          pageSubHeading={"Symbol of Wisdom & History"}
          inPageBGImage={bgInternalImage}
        />
        <TitleTextArticle h3TitleText={"The Symbol"} />
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                  The Sankofa Bird is an adinkra symbol from the Akan people of
                  Côte d’Ivoire and Ghana. According to the Carter G. Woodson
                  Center for Interracial Education at Berea College, the word is
                  made up of three individual words which mean:
                </p>
                <ul>
                  <li>SAN (return)</li>
                  <li>KO (go) </li>
                  <li>FA (look, seek and take) </li>
                </ul>
                <p className="articleText">
                  Traditionally the proverb goes “Se wo were fi na wosan kofa a
                  yenkyiri,” translating to “It is not taboo to go back for what
                  you forgot (or left behind).” It is a gentle and humbling
                  reminder to bring the past forward to remember, make amends,
                  and understand its proper place in the now.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <ArticleText
          articleText={`The lessons of Sankofa are symbolized popularly by the Sankofa bird, a mythical creature contouring its neck 
          back to retrieve an egg while its feet stand firmly planted. The other symbol is a figure that looks like a symmetrical 
          heart spotting two spirals on the interior top and the other at the bottom exterior along with three points. The symbol 
          indicates the pursuit of knowledge that is founded in critical analysis and patient deliberation. `}
        />
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                  “With every appeal to show regard for the past comes the
                  objections about its obvious flaws. We need not deify the
                  actors nor conjure up hagiographies for them to learn from
                  them. Neither do we need to sanitize their legacies or purge
                  their sins from memory. We only need to take their histories
                  for what they are&#8212;flawed as they were, blind as they
                  were, wrong as they were – for we are likely no better. Our
                  progeny will make similarly damning if not worse assessments
                  of our lives when we are gone. It behooves us to sift the
                  wheat from the chaff and draw the useful lessons.” <br />
                  &#8212;{" "}
                  <a
                    className="hyperlinks"
                    href="https://www.adinkrasymbols.org/symbols/sankofa/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Adinkra Symbols & Meanings
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <LargeFeaturedImg
          imgSrc={SankofaStatueImg}
          imgAlt={"A statue of the Sankofa bird"}
        />
        <TitleTextArticle h3TitleText={"Dr. Hilliard-Nunn & Her Legacy"} />
        <ArticleText
          articleText={`Truth and Reconciliation, as outlined by the Equal Justice Initiative, was brought to Alachua County in part by the labor 
          of Dr. Patricia Hilliard-Nunn. An activist, scholar, filmmaker, artist, professor, dancer, and visionary, Dr. Hilliard-Nunn’s groundbreaking 
          research brought to light the racial terror lynchings that had occurred in the City of Newberry. Her work paved the way for Newberry to begin
           reconciling with its history of racial terrorism, allowing for healing to take place within the community. Along with her work unearthing 
           racial terror lynching in Alachua County, Dr. Hilliard-Nunn spent countless hours documenting the Rosewood Massacre and plantations throughout
            the region. Her research brought to light throughlines of racial terror throughout Alachua County’s history and situated these truths in 
            critical modern dialogues on racial violence. 
          `}
        />
        <ArticleText
          articleText={`At the center of Dr. Hilliard-Nunn’s work is an unwavering commitment to protecting humanity. Locally, she pushed for historians 
          and institutions to begin referring to “slaves” as enslaved people in recognition of the person that had that status forced upon them. The 
          principals upon which the EJI’s work rests similarly centers the individuality and personhood of those who were killed in racial terror lynchings. 
          While her work often approached areas of trauma and violence, it did so in recognition of the role acknowledgement plays in helping individuals move forward. `}
        />
        <div className="articleText">
          <Container>
            <Row>
              <Col className="hold-article">
                <p className="articleText">
                  The mark that Dr. Hilliard-Nunn has left on Alachua County
                  extends beyond the Community Remembrance Project. Exhibits
                  showing Dr. Hilliard-Nunn’s art and her plantation research
                  are on display at the Cotton Club Museum and Cultural Center.
                  Her 2009 documentary that she made in collaboration with
                  Alachua County, entitled “In the Shadow of Plantations”,
                  remains available for viewing: &nbsp;
                  <a
                    className="hyperlinks"
                    href="https://www.youtube.com/watch?v=AMjWEjQy7yI"
                    target="_blank"
                    rel="noreferrer"
                  >
                    In the Shadow of Plantations
                  </a>.
                  On Juneteenth of 2023, the County unveiled the Sankofa Statue in honor of the life and legacy of Dr. Hilliard-Nunn, who featured the symbol in her lectures and artwork. 
                </p>
                <p><a className="hyperlinks"  target="_blank"
                    rel="noreferrer" href="/files/curriculum/dr_nunn_eulogy.docx"> - Read Dr. Patricia Hilliard Nunn's Eulogy's Here</a> </p>
              </Col>
             
            </Row>
          </Container>
        </div>
        <Blockquote
          quoteText={`" When you stand together, love one another, study,
            and focus, there's nothing you can't do. "`}
          quoteSource={"Dr. Patricia Hilliard-Nunn"}
        />
        <Footer />
      </main>
    );
  }
}

export default AboutSankofa;
